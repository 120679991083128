import { Button, Card, Container, SimpleGrid } from '@mantine/core';

import { useStyles } from "../components/styles";
import { useTranslation } from "react-i18next";

/**
 * The HomePage component renders the welcome page of the application.
 */
export const HomePage = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

    return (
        <Container>
            <h1>{t("HomePage.title")}</h1>
            <p>{t("HomePage.introduction")}</p>
            <h2>{t("HomePage.notfalltitle" )}</h2>
            <SimpleGrid spacing="xl" cols={3} breakpoints={[{maxWidth: 'md', cols: 2}, {maxWidth: 'sm', cols: 1}]}>
                <Card shadow="md" className={classes.cardBody}>
                    <Card.Section className={classes.cardTitle}>
                        <h3>{t("HomePage.alarmzentraletitle")}</h3>
                    </Card.Section>
                    <Card.Section className={classes.cardText}>
                        <p>{t("HomePage.alarmzentralebeschr")}</p>
                    </Card.Section>
                </Card>
                <Card shadow="md" className={classes.cardBody}>
                    <Card.Section className={classes.cardTitle}>
                        <h3>{t("HomePage.extnotfalltitle")}</h3>
                    </Card.Section>
                    <Card.Section className={classes.cardText}>
                        <p>{t("HomePage.extnotbeschr")}</p>
                    </Card.Section>
                </Card>  
                <Card shadow="md" className={classes.cardBody}>
                    <Card.Section className={classes.cardTitle}>
                        <h3>{t("HomePage.notfallweiteretitle")}</h3>
                    </Card.Section>
                    <Card.Section className={classes.cardText}>
                        <p>{t("HomePage.notfallweiterebeschr")}</p>
                    </Card.Section>
                    <Card.Section className={classes.cardFooter}>
                        <Button className={classes.button} component="a" href="https://ethz.ch/content/dam/ethz/associates/services/Service/sicherheit-gesundheit-umwelt/files/sicher_unterwegs_an_der_eth/en/sgu-sicher_unterwegs_an_der_eth_en.pdf" target="_blank">PDF</Button>
                    </Card.Section>
                </Card>        
            </SimpleGrid>
            <h2>{t("HomePage.psychologischeberatung")}</h2>
            <SimpleGrid spacing="xl" cols={3} breakpoints={[{maxWidth: 'md', cols: 2}, {maxWidth: 'sm', cols: 1}]}>
                <Card shadow="md" className={classes.cardBody}>
                    <Card.Section className={classes.cardTitle}>
                        <h3>{t("HomePage.psychberatungtitle")}</h3>
                    </Card.Section>
                    <Card.Section className={classes.cardText}>
                        <p>{t("HomePage.psychberatungbeschr")}</p>
                    </Card.Section>
                    <Card.Section className={classes.cardFooter}>
                        <Button className={classes.button} component="a" href="https://www.pbs.uzh.ch/de.html" target="_blank">Website</Button>
                    </Card.Section>
                </Card>
                <Card shadow="md" className={classes.cardBody}>
                    <Card.Section className={classes.cardTitle}>
                        <h3>{t("HomePage.nightlinetitle")}</h3>
                    </Card.Section>
                    <Card.Section className={classes.cardText}>
                        <p>{t("HomePage.nightlinebeschr")}</p>
                    </Card.Section>
                    <Card.Section className={classes.cardFooter}>
                        <Button className={classes.button} component="a" href="https://www.nightline.ch/public/en/home/" target="_blank">Website</Button>
                    </Card.Section>
                </Card>  
                <Card shadow="md" className={classes.cardBody}>
                    <Card.Section className={classes.cardTitle}>
                        <h3>{t("HomePage.psychweiteretitle")}</h3>
                    </Card.Section>
                    <Card.Section className={classes.cardText}>
                        <p>{t("HomePage.psychweiterebeschr")}</p>
                    </Card.Section>
                </Card>        
            </SimpleGrid>
            
            <h2>{t("HomePage.respekttitle" )}</h2>
            <SimpleGrid spacing="xl" cols={3} breakpoints={[{maxWidth: 'md', cols: 2}, {maxWidth: 'sm', cols: 1}]}>
                <Card shadow="md" className={classes.cardBody}>
                    <Card.Section className={classes.cardTitle}>
                        <h3>{t("HomePage.respstelletitle")}</h3>
                    </Card.Section>
                    <Card.Section className={classes.cardText}>
                        <p>{t("HomePage.respstelleintro")}</p>
                    </Card.Section>
                    <Card.Section className={classes.cardFooter}>
                        <Button className={classes.button} component="a" href="https://ethz.ch/studierende/de/beratung/hilfe-unangemessenes-verhalten-konflikte/respektstelle.html" target="_blank">Website</Button>
                    </Card.Section>
                </Card>
                <Card shadow="md" className={classes.cardBody}>
                    <Card.Section className={classes.cardTitle}>
                        <h3>{t("HomePage.unangemessenestitle")}</h3>
                    </Card.Section>
                    <Card.Section className={classes.cardText}>
                        <p>{t("HomePage.unangemessenesbeschr")}</p>
                    </Card.Section>
                    <Card.Section className={classes.cardFooter}>
                        <Button className={classes.button} component="a" href="https://drive.google.com/file/d/1mDks2E1TG8XyJ71KVKbrCts-Ox8Xiihp/view" target="_blank">PDF</Button>
                    </Card.Section>
                </Card>  
                <Card shadow="md" className={classes.cardBody}>
                    <Card.Section className={classes.cardTitle}>
                        <h3>{t("HomePage.ombudstitle")}</h3>
                    </Card.Section>
                    <Card.Section className={classes.cardText}>
                        <p>{t("HomePage.ombudsbeschr")}</p>
                    </Card.Section>
                    <Card.Section className={classes.cardFooter}>
                            <Button className={classes.button} component="a" href="https://ethz.ch/services/de/organisation/ombuds-und-vertrauenspersonen.html" target="_blank">Website</Button>
                    </Card.Section>
                </Card> 
                <Card shadow="md" className={classes.cardBody}>
                    <Card.Section className={classes.cardTitle}>
                        <h3>{t("HomePage.avethtitle")}</h3>
                    </Card.Section>
                    <Card.Section className={classes.cardText}>
                        <p>{t("HomePage.avethbeschr")}</p>
                    </Card.Section>
                    <Card.Section className={classes.cardFooter}>
                        <Button className={classes.button} component="a" href="https://www.aveth.ethz.ch/counselling/" target="_blank">Website</Button>
                    </Card.Section>
                </Card> 
                <Card shadow="md" className={classes.cardBody}>
                    <Card.Section className={classes.cardTitle}>
                        <h3>{t("HomePage.respektextern")}</h3>
                    </Card.Section>
                    <Card.Section className={classes.cardText}>
                        <p>{t("HomePage.respektexternbeschr")}</p>
                    </Card.Section>
                    <Card.Section className={classes.cardFooter}>
                        <Button className={classes.button} component="a" href="https://www.opferhilfe-schweiz.ch/de/" target="_blank">Website</Button>
                    </Card.Section>
                </Card>  
            </SimpleGrid>
            <h2>{t("HomePage.studienalltag" )}</h2>
            <SimpleGrid spacing="xl" cols={3} breakpoints={[{maxWidth: 'md', cols: 2}, {maxWidth: 'sm', cols: 1}]}>
                <Card shadow="md" className={classes.cardBody}>
                    <Card.Section className={classes.cardTitle}>
                        <h3>{t("HomePage.helpdesk")}</h3>
                    </Card.Section>
                    <Card.Section className={classes.cardText}>
                        <p>{t("HomePage.helpdeskbeschr")}</p>
                    </Card.Section>
                    <Card.Section className={classes.cardFooter}>
                        <Button className={classes.button} component="a" href="./helpdesk" target="_blank">Website</Button>
                    </Card.Section>
                </Card>
                <Card shadow="md" className={classes.cardBody}>
                    <Card.Section className={classes.cardTitle}>
                        <h3>{t("HomePage.bercoachtitle")}</h3>
                    </Card.Section>
                    <Card.Section className={classes.cardText}>
                        <p>{t("HomePage.bercoachintro")}</p>
                    </Card.Section>
                    <Card.Section className={classes.cardFooter}>
                        <Button className={classes.button} component="a" href="https://ethz.ch/studierende/de/beratung/beratung-coaching.html" target="_blank">Website</Button>
                    </Card.Section>
                </Card>
                <Card shadow="md" className={classes.cardBody}>
                    <Card.Section className={classes.cardTitle}>
                        <h3>{t("HomePage.administrativestitle")}</h3>
                    </Card.Section>
                    <Card.Section className={classes.cardText}>
                        <p>{t("HomePage.administrativesbeschr")}</p>
                    </Card.Section>
                    <Card.Section className={classes.cardFooter}>
                        <Button className={classes.button} component="a" href="https://ethz.ch/students/en/studies/administrative.html" target="_blank">Website</Button>
                    </Card.Section>
                </Card>
                <Card shadow="md" className={classes.cardBody}>
                    <Card.Section className={classes.cardTitle}>
                        <h3>{t("HomePage.rechtsberatungtitle")}</h3>
                    </Card.Section>
                    <Card.Section className={classes.cardText}>
                        <p>{t("HomePage.rechtsberatungbeschr")}</p>
                    </Card.Section>
                    <Card.Section className={classes.cardFooter}>
                        <Button className={classes.button} component="a" href="https://vsuzh.ch/rechtsberatung" target="_blank">VSUZH-Website</Button>
                        <Button className={classes.button} component="a" href="https://ethz.ch/studierende/de/studium/rechtsweg.html" target="_blank">ETH-Website</Button>
                    </Card.Section>
                </Card>
                <Card shadow="md" className={classes.cardBody}>
                    <Card.Section className={classes.cardTitle}>
                        <h3>{t("HomePage.finanziellestitle")}</h3>
                    </Card.Section>
                    <Card.Section className={classes.cardText}>
                        <p>{t("HomePage.finanziellesbeschr")}</p>
                    </Card.Section>
                    <Card.Section className={classes.cardFooter}>
                        <Button className={classes.button} component="a" href="https://ethz.ch/students/en/studies/financial.html" target="_blank">Website</Button>
                    </Card.Section>
                </Card>  
                <Card shadow="md" className={classes.cardBody}>
                    <Card.Section className={classes.cardTitle}>
                        <h3>{t("HomePage.wohnsituationtitle")}</h3>
                    </Card.Section>
                    <Card.Section className={classes.cardText}>
                        <p>{t("HomePage.wohnsituationbeschr")}</p>
                    </Card.Section>
                    <Card.Section className={classes.cardFooter}>
                        <Button className="mb-2 mr-1" color="primary" component="a" href="https://www.wohnen.ethz.ch/" target="_blank">Website</Button>
                        <Button className={classes.button} component="a" href="https://www.wohnen.ethz.ch/de/wohnraum-suchen/weitere-suchmoeglichkeiten.html " target="_blank">Further Searchingtools</Button>
                    </Card.Section>
                </Card>
                <Card shadow="md" className={classes.cardBody}>
                    <Card.Section className={classes.cardTitle}>
                        <h3>{t("HomePage.austauschtitle")}</h3>
                    </Card.Section>
                    <Card.Section className={classes.cardText}>
                        <p>{t("HomePage.austauschbeschr")}</p>
                    </Card.Section>
                    <Card.Section className={classes.cardFooter}>
                        <Button className={classes.button} component="a" href="https://ethz.ch/en/studies/international.html" target="_blank">Website</Button>
                    </Card.Section>
                </Card>
                <Card shadow="md" className={classes.cardBody}>
                    <Card.Section className={classes.cardTitle}>
                        <h3>{t("HomePage.behinderungtitle")}</h3>
                    </Card.Section>
                    <Card.Section className={classes.cardText}>
                        <p>{t("HomePage.behinderungbeschr")}</p>
                    </Card.Section>
                    <Card.Section className={classes.cardFooter}>
                        <Button className={classes.button} component="a"  href="https://ethz.ch/studierende/de/beratung/besondere-studiensituationen/studium-und-behinderung.html" target="_blank">Website</Button>
                    </Card.Section>
                </Card>
                <Card shadow="md" className={classes.cardBody}>
                    <Card.Section className={classes.cardTitle}>
                        <h3>{t("HomePage.kindtitle")}</h3>
                    </Card.Section>
                    <Card.Section className={classes.cardText}>
                        <p>{t("HomePage.kindbeschr")}</p>
                    </Card.Section>
                    <Card.Section className={classes.cardFooter}>
                        <Button className={classes.button} component="a" href="https://ethz.ch/studierende/de/beratung/besondere-studiensituationen/studieren-mit-kind.html" target="_blank">Website</Button>
                    </Card.Section>
                </Card>
            </SimpleGrid>
            <Card color="primary" className="mt-5 mb-5">
                <Card.Section className="ml-0 mt-2">
                    <h3>{t("HomePage.kontakttitle")}</h3>
                </Card.Section>
                <p>{t("HomePage.kontaktbeschr")}</p>
            </Card>
        </Container>
    );
};
